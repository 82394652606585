.App {
  text-align: center;
  display: flex;
  justify-content: center;
  /* width: calc(100vw - 1rem);
  height: calc(100vh - 1rem);
  border: 0.5rem solid #E26453; */
  background-color: #F6EEEB;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media (min-width: 620px){
  html {
    font-size: 27px !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: 'Big Caslon'; /* 폰트 이름 지정 */
  src: url('../public/fonts/BigCaslonFB-Regular.woff2') format('woff2'), /* 폰트 경로 지정 */
       url('../public/fonts/BigCaslonFB-Regular.woff') format('woff'); /* 다른 형식의 폰트도 지정할 수 있음 */
}
@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FONTSPRING DEMO - Juana Alt Black';
  src: url("../public/fonts/FSP DEMO - Juana Alt Black.woff2")format("woff2"),
  url("../public/fonts/FSP DEMO - Juana Alt Black.woff")format("woff");
  font-weight: 400;
  font-style: normal;
}
/* 
@import url('https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap') */