/* common.css는 모바일 청첩장 공통영역 정의합니다. */
html{-webkit-text-size-adjust:none; -moz-text-size-adjust:none; -ms-text-size-adjust:none; font-size:4.445vmin;}
html, body{width:100%; height:100%;}


/* ******************************** 미디어 쿼리 ******************************** */
 /* Landscape mode (가로형 디바이스) */
 /* @media only screen and (orientation: landscape) {
	html{font-size:4.445vmax;}

} */
 
/* DeskTop */
/* @media all and (min-width:720px) {
	html{font-size:32px !important;}  
	html, body{ margin:0 auto;}
} */

body{
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  line-height: 1.5;
  color: #111;
  word-wrap: break-word;
  word-break: break-all;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html, body, blockquote, caption, dd, div, dl, dt, fieldset, form, frame, h1, h2, h3, h4, h5, h6, hr, iframe, input, legend, li, object, ol, p, pre, q, select, table, textarea, ul, tr, td, span {
  margin: 0;
  padding: 0;
  font-family: 'Gowun Dodum', sans-serif, 'helvetica', serif, 'AppleGothic';
}